/* ---------------------------------------------------
    SWIPE SLIDER STYLE
----------------------------------------------------- */
.swiper {
	position: static !important;
}

.swiper-slide {
	width: auto !important;
}

.swiper-pagination {
	display: flex !important;
	justify-content: flex-end !important;
	width: 100% !important;
	height: 7px !important;
	top: 0 !important;
	left: initial !important;
	right: 0% !important;
	transform: translateX(0%) !important;
}

.swiper-pagination-bullet {
	width: 25px !important;
	height: 7px !important;
	background-color: #8643ff !important;
	border-radius: 0.375rem !important;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
	transform: scale(0.4, 1) !important;
}

.swiper-button-disabled {
	display: none !important;
}

.swiper-button-prev,
.swiper-button-next {
	display: none !important;
	width: 30px !important;
	height: 92.5% !important;
	z-index: 1 !important;
	position: absolute !important;
	top: 1rem !important;
}

.swiper-button-prev {
	left: 0 !important;
}

.swiper-button-next {
	right: 0 !important;
}

.swiper-button-prev:before {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
	align-content: center !important;
	width: 100% !important;
	height: 100% !important;
	cursor: pointer !important;
	content: $swiper-prev-icon !important;
	opacity: 0.9 !important;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0.68), rgba(255, 255, 255, 0));
}

.swiper-button-next:before {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
	align-content: center !important;
	width: 100% !important;
	height: 100% !important;
	cursor: pointer !important;
	content: $swiper-next-icon !important;
	opacity: 0.9 !important;
	background-image: linear-gradient(to left, rgba(255, 255, 255, 0.68), rgba(255, 255, 255, 0));
}

[data-bs-theme=dark] {
	.swiper-button-prev:before {
		content: $swiper-prev-dark-icon !important;
		background-image: linear-gradient(to right, rgba(61, 61, 61, 0.72), rgba(72, 72, 72, 0));
	}

	.swiper-button-next:before {
		content: $swiper-next-dark-icon !important;
		background-image: linear-gradient(to left, rgba(61, 61, 61, 0.72), rgba(72, 72, 72, 0));
	}
}

.swiper:hover {

	.swiper-button-prev,
	.swiper-button-next {
		display: block !important;
		transition: all .5s ease-in-out;
	}

	.swiper-button-disabled {
		display: none !important;
	}
}

.hottest-swipe-slider {
	padding-top: 1.25rem !important;

	.swiper-button-prev {
		top: 10.5% !important;
		height: 200.5px !important;
		left: 0.25rem !important;
	}

	.swiper-button-next {
		top: 10.5% !important;
		height: 200.5px !important;
		right: 0.25rem !important;
	}

	.swiper-pagination {
		top: 10px !important;
		right: 0.25rem !important;
	}
}

.button-swipe-slider {

	.swiper-button-prev,
	.swiper-button-next {
		height: 94.2% !important;
	}
}

.explore-swipe-slider {

	.swiper-button-prev,
	.swiper-button-next {
		top: 1.5rem !important;
		height: 87% !important;
	}

	.swiper-pagination {
		left: 0 !important;
		justify-content: flex-start !important;
	}
}

.industry-swiper-slider {
	width: 80% !important;
	height: 48px !important;

	.swiper-slide {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
	}
}
/* ---------------------------------------------------
    INPUT STYLE
----------------------------------------------------- */
.verification-input {
    font-size: 18px !important;
    font-weight: 400 !important;
    border: 1px solid var(--border-color) !important;
    width: 40px !important;
    height: 35px !important;
    padding: 0 !important;
}

.verification-input[value=""] {
    border-color: #C4C4C4 !important;
}

.verification-input::-webkit-inner-spin-button {
    display: none !important;
}

.verification-input-div {
    width: 233px !important;
}

.signup-option-selected {
    border: 1px solid var(--post-button-bg-color) !important;
}

.signup-option {
    input {  
        display: none !important;
    }
    .bg-purple {
        background: #684099 !important;
    }
}

.form-control:focus, .form-select:focus {
    border-color: $primary !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px $primary !important;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
    padding: 0.375rem 0.75rem !important;
    height: auto !important;
    min-height: auto !important;
    line-height: 1.5 !important;
}

.form-floating > label {
    padding: 0.5rem 0.75rem !important;
    top: -2px !important;
    color: hsl(0, 0%, 50%) !important;
    height: auto !important;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    padding: 0rem !important;
    left: 0.75rem !important;
    transform: scale(0.85) translateY(-0.25rem) translateX(0.15rem) !important;
    line-height: 0.75rem !important;
    background: white !important;
}

.form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {
    height: auto !important;
}

.search-input:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px #EAEAEA !important;
}

.input-group:focus-within {
    outline: 1px solid $primary !important;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px $primary !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
    border-color: $primary !important;
    border-radius: 50% !important;
    border-width: 1px !important;
    border-style: solid !important;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: none !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    width: 100% !important;
    margin-left: 0 !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 100% !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.react-datepicker__input-time-container {
    margin: 5px 0 10px 0px !important;
}

.react-datepicker__navigation {
    height: 59px !important;
}

.react-datepicker__close-icon::after {
    background-image: url("../../images/common/modal-close.svg") !important;
    background-repeat: no-repeat;
    background-size: 20px 20px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    content: "" !important;
    width: 20px !important;
    height: 20px !important;
}

#search-lg-form, #search-form {
    .input-group {
        background: #E7DAFF4D !important; 
    }
}

#navbar-lg-search::placeholder, #navbar-search::placeholder {
    font-style: italic !important;
    font-size: 12px !important;
}

#navbar-lg-search:placeholder-shown, #navbar-search:placeholder-shown {
  text-overflow: ellipsis !important;
}

.theme-switch {
    width: 50px !important;
}

.comment-compose-textarea {
	resize: none !important;
	line-height: 24px !important;
	overflow: auto !important;
	height: auto !important;
}

.premium-check {
    label {
        width: 100% !important;
    }

    input {
        margin-left: 0 !important;
    }

    .recommended {
        border-top-left-radius: 0rem !important;
        border-bottom-left-radius: 0.8rem !important;
        border-bottom-right-radius: 0rem !important;
    }
}

.industry-label {
    display: flex !important;
    flex-direction: row-reverse !important;

    input {
        margin-right: 0 !important;
    }

    label {
        width: 100% !important;
        display: flex !important;
    }
}

.ignite-over-time-select, .datepicker-dropdown {
    .css-sj0jxu-control, .css-sj0jxu-control:hover {
        border: none !important;
    }
}

.css-5736gi-menu {
    z-index: 100000 !important;
}

.cookie-checkbox {
    label {
        display: grid !important;
    }
}

[data-bs-theme=dark] {
    input, select, textarea, textarea:focus, input:focus, .form-label {
        color: white !important;
    }

    .form-control:focus, .form-select:focus {
        border-color: white !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px white !important;
    }

    .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label { 
        background: #221F2E !important;
    }

    .input-group:focus-within {
        outline: 1px solid white !important;
    }

    .input-group:focus-within *:focus {
        border-color: transparent !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px white !important;
    }

    .signup-option {
        .bg-purple {
            background: white !important;
        }
    }

	.css-13r3304-control {
		background: transparent !important;
        border-color: #495057 !important;
	}

    .css-1jqq78o-placeholder, .css-tj5bde-Svg {
        color: #AFB1AF !important;
    }

    .css-164yvry-control, .css-13r3304-control:hover, .css-164yvry-control:hover {
        border: 1px solid white !important;
        box-shadow: 0px 0px 2px white !important;
    }

	.css-164yvry-control, .css-b62m3t-container {
		background: #221F2E !important;
	}

	.css-1dimb5e-singleValue {
		color: white !important;
	}

    .css-1nmdiq5-menu {
        background: #221F2E !important;
    }

    .react-datepicker {
        background: #221F2E !important;
        color: white !important;
    }

    .react-datepicker__header--custom {
        background: #221F2E !important;
        color: white !important;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: white !important;
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
        color: grey !important;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background: $primary !important;
    }

    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
        background: $primary !important;
    }
}

/* ---------------------------------------------------
    PHONE NUMBER INPUT STYLE
----------------------------------------------------- */
.PhoneInput {
	input {
		display: block !important;
		width: 100% !important;
		padding: 0.375rem 0.75rem !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		line-height: 1.5 !important;
		color: var(--bs-body-color) !important;
		appearance: none !important;
		background-color: var(--bs-body-bg) !important;
		background-clip: padding-box !important;
		border: 0 !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
		padding-right: 0 !important;
	}

	input:focus-visible {
		outline: 0 !important;
	}
}

.PhoneInput--focus {
	input {
		border: 0 !important;
	}

	input:focus-visible {
		outline: 0 !important;
	}
}

.PhoneInput {
    &:focus-within {
        border-color: $primary !important;
    }
}

/* ---------------------------------------------------
    REACT SELECT STYLE
----------------------------------------------------- */
.css-1u9des2-indicatorSeparator {
	display: none !important;
}
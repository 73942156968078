/* ---------------------------------------------------
    DASHBOARD STYLE
----------------------------------------------------- */
.hottest-div {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
    background-repeat: no-repeat !important;
    background-origin: border-box !important;
    background-position: center !important;
    background-size: cover !important;
    padding: 34px 5% !important;
    height: 150px !important;

    img {
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }
}

.hottest-div-slider {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.recommend-brand-div {
    min-height: 220px !important;
    max-height: 410px !important;
}

.product-compare-div:hover {
    border: 1px solid $primary !important;
}

.product-compare {
    max-height: 126px !important;
    max-width: 125px !important;
    width: 100% !important;

    img {
        width: 90px !important;
        height: 90px !important;
        aspect-ratio: auto 90 / 90 !important;
    }
}

.up-down-ignite {
    color: #c33004 !important
}

.ignite-count-div {
    min-width: 75px !important;
    max-width: 90px !important;
}

.recommend-brand-blur {
    width: 95% !important;
    height: 211px !important;
}

.recommend-brand-blur-div {
    top: 16px !important;
    height: calc(100% - 48px) !important;
    filter: blur(10px) !important;
    -webkit-filter: blur(10px) !important;

    .swiper {
        width: 100% !important;
    }

    .swiper-slide {
        height: 100% !important;
    }
}

/* ---------------------------------------------------
    GRAPH STYLE
----------------------------------------------------- */
.cluster-label {
    font-size: 10px !important;
}

[data-bs-theme=dark] {
    .cluster-label {
        fill: white !important;
    }
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.commitment-div {
    background-color: #30293E !important;
}

.premium-badge, .premium-button, .feature-badge {
    background-color: #398E3C !important;
}

.premium-button {
    border-color: #398E3C !important;
}

.premium-badge:after, .feature-badge:after {
    content: "" !important;
    position: absolute !important;
    width: 24px !important;
    height: 10px !important;
    top: 24px !important;
    right: -7px !important;
    z-index: -1 !important;
    border-top: 16px solid #120036 !important;
    border-right: 15px solid transparent !important;
    border-left: 15px solid transparent !important;
    transform: rotate(-90deg) !important;
}

.feature-badge:after {
    top: 16px !important;
}

.premium-text {
    color: #398E3C !important;
}

.package-icon {
    width: 46px !important;
	height: 46px !important;
}

/* ---------------------------------------------------
    SCROLLSPY STYLE
----------------------------------------------------- */
.active-scroll-spy {
	color: $primary !important;
	text-decoration: none !important;
}

//------------------------------------------
.items ul {
    padding-top: 20px !important;
    padding-left: 0px !important;
    position: relative !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center !important;

    @media screen and (max-width: 991px) {
        flex-direction: column !important;
        align-items: center !important;
    }
}

/* Make all children "inline" */
.items li {
    display: inline-block !important;
    text-align: center !important;
    list-style-type: none !important;
    position: relative !important;
    padding-top: 20px !important;
    padding-right: 5px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
}

/* Add horizontal connector. Note: they are 2 pseudo-elements */
.items li::before, .items li::after {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    right: 50% !important;
    width: 55% !important;
    height: 22px !important;
    z-index: -1 !important;
    border-top: 1px solid #6E768E !important;

    @media screen and (max-width: 991px) {
        content: "" !important;
        border-left: 1px solid #6E768E !important;
        z-index: -1 !important;
        height: 35px !important;
        position: absolute !important;
        top: -15px !important;
        left: 50% !important;
        width: 0 !important;
    }
}

.items li::after {
    border-left: 1px solid #6E768E !important;
    left: 50% !important;
    right: auto !important;

    @media screen and (max-width: 991px) {
        border-left: 0 !important;
    }
}

/* Remove left and right connector from a single child */
.items li:only-child::after, .items li:only-child::before {
    display: none !important;
}

.items li:only-child {
    padding-top: 0 !important;
}

.items li:first-child {
    @media screen and (max-width: 991px) {
        padding-top: 0 !important;
    }
}

/* Remove "outer" connector */
.items li:first-child::before, .items li:last-child::after {
    border: 0 none !important;
}

/* Add back the down connector for last node */
.items li:last-child::before {
    border-right: 1px solid #6E768E !important;
    border-top-right-radius: 5px !important;

    @media screen and (max-width: 991px) {
        border-right: 0 !important;
    }
}

/* Add curve line to the first child's connector */
.items li:first-child::after {
    border-top-left-radius: 5px !important;
}

/* Add down connector from parent */
.items ul ul::before {
    content: '' !important;
    border-left: 1px solid #6E768E !important;
    z-index: -1 !important;
    height: 35px !important;
    position: absolute !important;
    top: -15px !important;
    left: 50% !important;
    width: 0 !important;
}

/* Add cosmetic for each item */
.items li div {
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-decoration: none !important;
    display: inline-block !important;
    border-radius: 4px !important;
    margin-right: 1rem !important;
    background-color: #f9fafb !important;

    @media screen and (max-width: 991px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

[data-bs-theme=dark] {
    .items li div {
        background-color: #272434 !important;
    }
}

/* EXPERIMENTAL for multiple parents */
/* Add margin for the parents */
.items li div:not(:only-of-type) {
    position: relative !important;
    margin-bottom: 16px !important;
}

/* Add "down" connector (vertical line) from each multi-parent, EXCEPT the last one */
.items li > div:not(:last-of-type)::after{
    content: '' !important;
    position: absolute !important;
    border-left: 1px solid #6E768E !important;
    border-bottom: 1px solid #6E768E !important;
    top: 20px !important;
    width: 75% !important;
    height: 20px !important;
    left: 50% !important;
    z-index: -1 !important;
}

/* Special case for the last multiple-parent, using border-right */
.items li > div:not(:only-of-type):last-of-type::after {
    content: '' !important;
    position: absolute !important;
    border-right: 1px solid #6E768E !important;
    border-bottom: 1px solid #6E768E !important;
    top: 20px !important;
    width: 50% !important;
    height: 20px !important;
    right: 50% !important;
    z-index: -1 !important; 
    border-bottom-right-radius: 5px !important;
}

/* Give the curve line to the first multiple parent .... */
.items li > div:not(:only-of-type):first-child::after {
    border-bottom-left-radius: 5px !important;
}

/* The middle element of multi-parents*/
.items li > div:not(:first-child):not(:last-of-type)::before {
    content: '' !important;
    position: absolute !important;
    border-bottom: 1px solid #6E768E !important;
    top: 40px !important;
    width: 50% !important;
    right: 50% !important;
    z-index: -1 !important;
}

.items ul:last-of-type li {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.items ul>li>ul>li>div {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    p {
        border-left: 1px solid #6E768E !important;

        &:before {
            content: '' !important;
            display: inline-block !important;
            width: 15px !important;
            height: 15px !important;
            -moz-border-radius: 7.5px !important;
            -webkit-border-radius: 7.5px !important;
            border-radius: 7.5px !important;
            background-color: $primary !important;
            position: absolute !important;
            left: 15px !important;

            @media screen and (min-width: 992px) {
                left: 5px !important;
            }
        }
    }

    ul {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        padding-top: 0 !important;
        padding-left: 0 !important;

        &:before {
            border: none !important;
        }

        li {
            border-left: 1px solid #6E768E !important;
        }

        li::before {
            border: none !important;
        }

        li::after {
            left: 0% !important;
            border: none !important;
            width: 20px !important;
            height: 34px !important;
            border-bottom: 1px solid #6E768E !important;
            z-index: 0 !important;
        }

        li:first-child {
            @media screen and (max-width: 991px) {
                padding-top: 20px !important;
            }
        }

        li:first-child::before {
            height: 60px !important;
        }

        li:first-child::after {
            border: none !important;
            border-bottom: 1px solid #6E768E !important;
            z-index: 0 !important;
        }

        li:last-child {
            border: none !important;
        }

        li:last-child::before {
            border: none !important;
        }

        li:last-child::after {
            border-top: 0 !important;
            border-right: 0 !important;
            border-left: 1px solid #6E768E !important;
            border-bottom: 1px solid #6E768E !important;
            border-radius: 0 0 0 5px!important;
            z-index: 0 !important;
        }

        li div {
            margin-left: 1rem !important;
            margin-right: 0 !important;
            padding: 0 !important;
            padding-left: 1rem !important;
            border: none !important;
            z-index: 1 !important;
            position: relative !important;
            background-color: transparent !important;
            text-align: left !important;

            @media screen and (max-width: 991px) {
                width: 200px !important;
            }
        }
    }
}

[data-bs-theme=dark] {
    .items ul>li>ul>li>div {
        ul {
            li {
                a {
                    color: #948EA0 !important;
                }

                div {
                    border: none !important;
                }
            }
        }
    }
}

@import './vars';

/* font applied in whole body */
body {
    font-family: 'Figtree', sans-serif !important;
}

body {
	overflow-x: hidden;
}

.App {
    text-align: start !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
}

/* ---------------------------------------------------
    LOGO STYLE
----------------------------------------------------- */
.site-logo, .footer-logo {
    width: 200px !important;
}

.loader-logo {
    width: 280px !important;
}

.brand-disable-icon {
    width: 32px !important;
}

.user-suspended-icon {
    width: 20px !important;
}

/* ---------------------------------------------------
    INDUSTRY STYLE
----------------------------------------------------- */
.selected-industry {
    background-color: var(--button-color) !important;
    border-color: var(--button-color)!important;
    color: var(--primary-text-color) !important;
}

.not-selected-industry {
    background-color: #e7e7e7 !important;
    border-color: var(--button-color)!important;
    color: var(--button-color) !important;
}

.subindustryselected-list {
    margin-bottom: 20px !important;
    grid-gap: 5px !important;
    gap: 5px !important;
    min-height: 30px !important;
    max-height: 90px !important;
    overflow-y: overlay !important;
}

/* ---------------------------------------------------
    BRAND STYLE
----------------------------------------------------- */
.recommend-brands-list {
	max-height: 630px !important;
	overflow: hidden !important;
    overflow-y: scroll !important;
}

/* ---------------------------------------------------
    USER STYLE
----------------------------------------------------- */
.profile-image {
    width: 40px !important;
    height: 40px !important;
	object-fit: cover !important;
    border: 1px solid transparent !important;
}

/* ---------------------------------------------------
    PAGINATE STYLE
----------------------------------------------------- */
.paginate-icon:hover {
	background-color: $primary !important;
	svg {
		color: white !important;
	}
}

/* ---------------------------------------------------
    PROGRESS BAR STYLE
----------------------------------------------------- */
.progress, .progress-stacked {
    --bs-progress-height: 2.125rem !important;
	--bs-progress-font-size: 14px !important;
}

.progress-bar-label {
	top: 6px !important;
}

/* ---------------------------------------------------
    EASY CROPPER STYLE
----------------------------------------------------- */
.reactEasyCrop_CropArea {
	border: none !important;
}

/* ---------------------------------------------------
    PASSWORD STRENGTH STYLE
----------------------------------------------------- */
.password-strength-div {
	height: 2px !important;
}

/* ---------------------------------------------------
    DEFAULT HEIGHT STYLE
----------------------------------------------------- */
.span-default-height {
	height: 24px !important;
}

/* ---------------------------------------------------
    TOAST STYLE
----------------------------------------------------- */
.toast {
    width: 32rem !important;
    border: none !important;
}

.toast-title, .toast-message {
    font-size: 1.125rem !important;
    line-height: normal !important;
}

/* ---------------------------------------------------
    FLAG ICONS STYLE
----------------------------------------------------- */
.fi {
	width: 30px !important;
    height: 22px !important;
    background-size: cover !important;
}
/* ---------------------------------------------------
    MARGIN STYLE
----------------------------------------------------- */
.mt-0-point-5 {
	margin-top: 0.15rem !important;
}

.mt-1-point-5 {
	margin-top: 0.313rem !important;
}

.mt-2-point-5 {
	margin-top: 0.8rem !important;
}

.mt-n2 {
	margin-top: -0.5rem !important;
}

.mt-n3 {
	margin-top: -1rem !important;
}

.mt-n4 {
	margin-top: -2rem !important;
}

.mt-n5 {
	margin-top: -3rem !important;
}

.mt-n6 {
	margin-top: -4rem !important;
}

.mb-2-point-2 {
	margin-bottom: 0.6rem !important;
}

.mb-2-point-5 {
	margin-bottom: 0.75rem !important;
}

.mt-4-point-5 {
	margin-top: 1.8rem !important;
}

.ms-3-point-5 {
	margin-left: 1.2rem !important;
}

.me-n3 {
	margin-right: -1rem !important;
}
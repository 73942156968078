/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */
.flag-navbar {
    width: 40px !important;
    height: 32px !important;
}

.navbar-button {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    font-size: clamp(0.875rem, 2vw, 1rem) !important;
}

.navbar-profile-image {
    width: 32px !important;
    height: 32px !important;
    object-fit: cover !important;
}

.own-brand-container {
    max-height: 140px !important;
    overflow-y: scroll !important;
}

.own-brand-div:hover {
    border: 1px solid $primary !important;
}

.brand-mention-image,
.own-brand-image {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover !important;
}
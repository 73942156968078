/* ---------------------------------------------------
    LOGO STYLE
----------------------------------------------------- */
.site-logo {
    @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 140px !important;
    }

    @media screen and (max-width: 550px) {
        width: 159px !important;
    }

    @media screen and (min-width: 356px) and (max-width: 425px) {
        width: 129px !important;
    }

    @media screen and (max-width: 355px) {
        width: 105px !important;
    }

    @media only screen and (max-width: 300px) {
        width: 70px !important;
    }
}

/* ---------------------------------------------------
    MODAL STYLE
----------------------------------------------------- */
.modal-md {
    @media (min-width: 576px) {
        max-width: 600px !important;
    }
}

.modal-sm {
    @media (min-width: 576px) {
        --bs-modal-width: 380px !important;
    }
}

.contact-modal {
    .modal-content {
        @media (min-width: 992px) {
            width: 980px !important;
        }
    }
}

.search-item-review-modal {
    .modal-content {
        @media (min-width: 768px) {
            width: 701px !important;
        }
    }
}

/* ---------------------------------------------------
    BRAND STYLE
----------------------------------------------------- */
.recommend-brands-list {
    @media (max-width: 991px) {
        height: 43vh !important;
    }
}

.brand-preview-image {
    @media (max-width: 575px) {
        width: 200px !important;
    }
}

/* ---------------------------------------------------
    CARD STYLE
----------------------------------------------------- */
.brand-follow-card {
    @media (max-width: 385px) {
        img {
            width: 105px !important;
            height: 105px !important;
            max-width: 100% !important;
            max-height: 100% !important;
            object-fit: contain !important;
        }

        .brand-follow-card-body {
            width: 120px !important;
        }
    }

    @media (max-width: 991px) {
        &-modal {
            width: 100% !important;
            max-width: 100% !important;

            .brand-follow-card-body {
                width: 100% !important;
            }
        }
    }
}

/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */
.profile-image {
    @media only screen and (max-width: 991px) {
        width: 36px !important;
        height: 36px !important;
    }
}

.navbar-button {
    @media only screen and (max-width: 240px) {
        font-size: 10px !important;
    }

    @media only screen and (min-width: 241px) and (max-width: 300px) {
        font-size: 12px !important;
    }

    @media only screen and (max-width: 361px) {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    @media only screen and (min-width: 992px) {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

.notification-svg-navbar {
    @media only screen and (max-width: 299px) {
        width: 5vw !important;
        height: 10vw !important;
    }
}

.medal-svg-navbar {
    @media only screen and (max-width: 299px) {
        width: 5vw !important;
        height: 12.5vw !important;
    }
}

.navbar-profile-image {
    @media only screen and (max-width: 299px) {
        width: 7vw !important;
        height: 7vw !important;
    }
}

.flag-navbar {
    @media only screen and (max-width: 299px) {
        width: 8.5vw !important;
        height: 7vw !important;
    }
}

.navbar-skeleton {
    @media only screen and (max-width: 991px) {
        height: 92px !important;
    }

    @media only screen and (min-width: 992px) {
        height: 38px !important;
    }
}

/* ---------------------------------------------------
    MARGIN STYLE
----------------------------------------------------- */
.mt-lg-n1 {
    @media only screen and (min-width: 992px) {
        margin-top: -0.25rem !important;
    }
}

.mt-6 {
    @media only screen and (max-width: 991px) {
        margin-top: 8rem !important;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 5rem !important;
    }
}

.me-lg-6 {
    @media only screen and (min-width: 992px) {
        margin-right: 10rem !important;
    }
}

/* ---------------------------------------------------
    LEFT SIDEBAR STYLE
----------------------------------------------------- */
.left-sidebar-xl {
    @media only screen and (min-width: 1200px) {
        width: 24% !important;
        min-width: 305px !important;
        max-width: 305px !important;
    }

    @media only screen and (min-width: 1300px) {
        width: 24% !important;
        min-width: 310px !important;
        max-width: 350px !important;
    }
}

/* ---------------------------------------------------
    RIGHT SIDEBAR STYLE
----------------------------------------------------- */
.right-sidebar-md {
    @media only screen and (min-width: 768px) {
        width: 38% !important;
        top: 124px !important;
        bottom: -25px !important;
        left: 60% !important;
        overflow-x: hidden !important;
    }

    @media only screen and (min-width: 992px) {
        top: 81px !important;
    }

    @media only screen and (min-width: 1200px) {
        width: 23% !important;
        top: 79px !important;
        bottom: -25px !important;
        left: 76% !important;
        overflow-x: hidden !important;
    }
}

.right-sidebar-explore-lg {
    @media only screen and (min-width: 992px) {
        width: 23% !important;
        max-width: 350px !important;
        top: 76px !important;
        bottom: -25px !important;
        right: 0 !important;
        overflow-x: hidden !important;
    }
}

/* ---------------------------------------------------
    POSITION STYLE
----------------------------------------------------- */
.position-md-fixed {
    @media only screen and (min-width: 768px) {
        position: fixed !important;
    }
}

.position-lg-fixed {
    @media only screen and (min-width: 992px) {
        position: fixed !important;
    }
}

/* ---------------------------------------------------
    USER PROFILE STYLE
----------------------------------------------------- */
.user-cover-photo {
    @media screen and (max-width: 684px) {
        height: 150px !important;
    }

    @media screen and (min-width: 685px) {
        height: 170px !important;
    }
}

.user-tab {
    @media screen and (max-width: 991px) {
        margin-left: 0rem !important;
        margin-top: 1.2rem !important;
        z-index: 1 !important;
        position: relative !important;

        li {
            margin-bottom: 0.5rem !important;
        }
    }
}

.reviews-content {
    @media screen and (min-width: 1200px) {
        min-height: 600px !important;
        max-height: 1000px !important;
    }
}

.user-profile-photo {
    @media screen and (min-width: 575px) {
        width: 105px !important;
        height: 105px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }
}

.product-wishlist-div {
    @media screen and (max-width: 991px) {
        background-size: cover !important;
    }
}

.matchbox-tab {
    @media screen and (max-width: 397px) {
        li:first-child {
            border-right: none !important;
        }
    }
}

/* ---------------------------------------------------
    BRAND PROFILE STYLE
----------------------------------------------------- */
.brand-cover-photo {
    @media screen and (max-width: 684px) {
        height: 200px !important;
    }

    @media screen and (min-width: 685px) {
        height: 245px !important;
    }
}

.brand-profile-photo {
    @media screen and (max-width: 375px) {
        width: 55px !important;
        height: 55px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }

    @media screen and (min-width: 376px) and (max-width: 575px) {
        width: 105px !important;
        height: 105px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 135px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }
}

.brand-tab {
    @media screen and (max-width: 991px) {
        margin-left: 0rem !important;
        z-index: 1 !important;
        position: relative !important;
    }
}

/* ---------------------------------------------------
    OVERFLOW STYLE
----------------------------------------------------- */
.overflow-xs-hidden {
    @media screen and (max-width: 991px) {
        overflow: hidden !important;
    }
}

.overflow-y-lg-auto {
    @media screen and (min-width: 992px) {
        overflow-y: auto !important;
    }
}

/* ---------------------------------------------------
    TOOLTIP STYLE
----------------------------------------------------- */
#product-tooltip {
    @media screen and (max-width: 575px) {
        width: 89vw !important;
    }

    @media screen and (min-width: 576px) and (max-width: 991px) {
        width: 550px !important;
    }
}

/* ---------------------------------------------------
    SWIPER SLIDER STYLE
----------------------------------------------------- */
.industry-swiper-slider {
    @media screen and (max-width: 575px) {
        width: 75% !important;
    }
}

/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
.content-header {
    @media screen and (max-width: 991px) {
        font-size: 30px !important;
    }
}

.text-md-end {
    @media screen and (min-width: 768px) {
        text-align: right !important;
    }
}

/* ---------------------------------------------------
    SKELETON STYLE
----------------------------------------------------- */
.profile-image-skeleton {
    @media only screen and (max-width: 991px) {
        width: 36px !important;
        height: 36px !important;
    }
}

.matchbox-span-skeleton {
    @media only screen and (max-width: 370px) {
        width: 60px !important;
        height: 10px !important;
    }
}

.subscribe-ignite-skeleton {
    @media only screen and (max-width: 370px) {
        width: 70px !important;
        height: 12px !important;
    }
}

.user-profile-photo-skeleton {
    @media screen and (max-width: 575px) {
        width: 105px !important;
        height: 105px !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 135px !important;
    }
}

.brand-profile-photo-skeleton {
    @media screen and (max-width: 375px) {
        width: 55px !important;
        height: 51px !important;
    }

    @media screen and (min-width: 376px) and (max-width: 575px) {
        width: 105px !important;
        height: 101px !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 131px !important;
    }
}

.brand-confidence-result-skeleton {
    @media screen and (max-width: 575px) {
        width: 65px !important;
        height: 36px !important;
    }
}

/* ---------------------------------------------------
    WIDTH STYLE
----------------------------------------------------- */
.w-xs-59 {
    @media screen and (max-width: 575px) {
        width: 59% !important;
    }
}

.w-sm-50 {
    @media screen and (min-width: 576px) {
        width: 50% !important;
    }
}

.w-md-50 {
    @media screen and (min-width: 768px) {
        width: 50% !important;
    }
}

/* ---------------------------------------------------
    PRODUCT STYLE
----------------------------------------------------- */
.product-tab {
    @media screen and (max-width: 575px) {
        button {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
    }
}

.product-comparison-div {
    @media screen and (max-width: 767px) {
        height: 832px !important;
    }
}

.product-comparison-feature {
    @media screen and (max-width: 575px) {
        height: 175px !important;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
        height: 166px !important;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 142px !important;
    }
}

/* ---------------------------------------------------
    PADDING STYLE
----------------------------------------------------- */
.px-xs-0-point-3 {
    @media screen and (max-width: 575px) {
        padding-right: 0.3rem !important;
        padding-left: 0.3rem !important;
    }
}

.px-xs-2-point-5 {
    @media screen and (max-width: 575px) {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.px-md-2-point-5 {
    @media screen and (min-width: 768px) {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.ps-md-2-point-5 {
    @media screen and (min-width: 768px) {
        padding-left: 0.75rem !important;
    }
}

.ps-xl-2-point-5 {
    @media screen and (min-width: 1200px) {
        padding-left: 0.75rem !important;
    }
}

/* ---------------------------------------------------
    BORDER STYLE
----------------------------------------------------- */
.border-sm-end {
    @media screen and (min-width: 576px) {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

.border-md-end {
    @media screen and (min-width: 768px) {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

.border-lg-top {
    @media screen and (min-width: 992px) {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

.border-lg-end {
    @media screen and (min-width: 992px) {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* ---------------------------------------------------
    POST STYLE
----------------------------------------------------- */
.post-action {
    @media screen and (max-width: 345px) {
        justify-content: space-between !important;
        width: 100% !important;
    }
}


/* ---------------------------------------------------
    DASHBOARD STYLE
----------------------------------------------------- */
.product-compare {
    @media screen and (min-width: 576px) and (max-width: 1199px) {
        img {
            width: 49px !important;
            height: 49px !important;
            aspect-ratio: auto 49 / 49 !important;
        }
    }
}

/* ---------------------------------------------------
    DASHBOARD GRAPH STYLE
----------------------------------------------------- */
.cluster-label {
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        font-size: 9px !important;
    }
}

/* ---------------------------------------------------
    INPUT STYLE
----------------------------------------------------- */
.premium-check {
    @media screen and (min-width: 992px) {
        label {
            width: calc(100% - 125px) !important;
        }
    }
}

.react-dateRangePicker {
    @media screen and (max-width: 767px) {
        display: inline-flex !important;
        flex-direction: column !important;
        overflow: scroll !important;
        height: 100vh !important;
        padding-bottom: 5rem !important;
    }
}

/* ---------------------------------------------------
    SKELETON STYLE
----------------------------------------------------- */
@media screen and (max-width: 992px) {
    .package {
        &-name-skeleton {
            height: 19px !important;
        }

        &-price-skeleton {
            width: 72px !important;
            height: 21px !important;
        }

        &-plan-skeleton {
            width: 81px !important;
            height: 14px !important;
        }

        &-feature {
            &-header-skeleton {
                width: 100px !important;
                height: 14px !important;
            }

            &-list {
                &-icon-skeleton {
                    width: 16px !important;
                    height: 16px !important;
                }

                &-title-skeleton {
                    height: 10px !important;
                }
            }
        }

        &-customizer {
            &-title-skeleton {
                width: 123px !important;
                height: 15px !important;
            }
        }
    }

    .metric {
        &-usages-skeleton {
            width: 10px !important;
            height: 14px !important;
        }
    }
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.package-container {
    @media screen and (max-width: 810px) {
        overflow-x: auto !important;

        &::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888 !important;
            border-radius: 50px !important;
            visibility: visible !important;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
            visibility: visible !important;
        }
    }
}

.package-row {
    @media screen and (max-width: 810px) {
        min-width: 810px !important;
    }
}